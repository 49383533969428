import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedService } from './shared/shared.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
// import { InterceptService } from './configs/interceptor.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpInterceptorProviders } from './configs/interceptor-providers';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbProgressbarModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    // OAuthModule.forRoot({
    //   resourceServer: {
    //     allowedUrls: [environment.BlueNumberDataImportAPI, environment.BlueNumberGlobalAPI,environment.BlueNumberDataReqAPI],
    //     sendAccessToken: true
    //   }
    // }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    SharedService,
    HttpInterceptorProviders
    // InterceptService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: InterceptService,
    //   multi: true
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
