import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {BehaviorSubject} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  loader: boolean = false;
  isLanguageChanged: boolean = false;
  remainingTimeForSurvey: BehaviorSubject<string> = new BehaviorSubject<string>('');
  submitButton: boolean = true;
  successErrorMsg: any = {
    messageHeading: '',
    message: '',
    showAcceptReject: false,
    showProceed: false,
    connectionCode: '',
    nextSurveyId: '',
    firstSurveyId: '',
    lastSurveyId: '',
    isMsgFromInterceptor: false,
    isRequestSurvey: false
  };
  messageText: any = '';

  private currencyMap: { [key: string]: string } = 
  { 
    AED: "د.إ", AFN: "؋", ALL: "L", AMD: "֏", ANG: "ƒ", AOA: "Kz", ARS: "$", 
    AUD: "A$", AWG: "ƒ", AZN: "₼", BAM: "KM", BBD: "$", BDT: "৳", BGN: "лв", 
    BHD: ".د.ب", BIF: "FBu", BMD: "$", BND: "$", BOB: "Bs.", BRL: "R$", BSD: "$", 
    BTN: "Nu.", BWP: "P", BYN: "Br", BZD: "BZ$", CAD: "C$", CDF: "FC", CHF: "Fr", 
    CLP: "$", CNY: "¥", COP: "$", CRC: "₡", CUP: "$", CVE: "$", CZK: "Kč", DJF: "Fdj", 
    DKK: "kr", DOP: "RD$", DZD: "د.ج", EGP: "£", ERN: "Nfk", ETB: "Br", EUR: "€", 
    FJD: "$", FKP: "£", FOK: "kr", GBP: "£", GEL: "₾", GHS: "GH₵", GIP: "£", 
    GMD: "D", GNF: "FG", GTQ: "Q", GYD: "$", HKD: "HK$", HNL: "L", HRK: "kn", 
    HTG: "G", HUF: "Ft", IDR: "Rp", ILS: "₪", INR: "₹", IQD: "ع.د", IRR: "﷼", 
    ISK: "kr", JMD: "J$", JOD: "د.ا", JPY: "¥", KES: "Sh", KGS: "с", KHR: "៛", 
    KMF: "CF", KPW: "₩", KRW: "₩", KWD: "د.ك", KYD: "$", KZT: "₸", LAK: "₭", 
    LBP: "ل.ل", LKR: "Rs", LRD: "$", LSL: "L", LYD: "ل.د", MAD: "د.م.", MDL: "L", 
    MGA: "Ar", MKD: "ден", MMK: "K", MNT: "₮", MOP: "P", MRU: "UM", MUR: "₨", 
    MVR: "ރ.", MWK: "MK", MXN: "$", MYR: "RM", MZN: "MT", NAD: "$", NGN: "₦", 
    NIO: "C$", NOK: "kr", NPR: "₨", NZD: "$", OMR: "ر.ع.", PAB: "B/.", PEN: "S/", 
    PGK: "K", PHP: "₱", PKR: "₨", PLN: "zł", PYG: "₲", QAR: "ر.ق", RON: "lei", 
    RSD: "дин.", RUB: "₽", RWF: "FRw", SAR: "ر.س", SBD: "$", SCR: "₨", SDG: "ج.س.", 
    SEK: "kr", SGD: "$", SHP: "£", SLE: "Le", SLL: "Le", SOS: "Sh", SRD: "$", SSP: "£", 
    STN: "Db", SYP: "£S", SZL: "E", THB: "฿", TJS: "ЅМ", TMT: "m", TND: "د.ت", TOP: "T$", 
    TRY: "₺", TTD: "TT$", TWD: "NT$", TZS: "Sh", UAH: "₴", UGX: "Sh", USD: "$", UYU: "$U", 
    UZS: "soʻm", VES: "Bs.S.", VND: "₫", VUV: "Vt", WST: "T", XAF: "FCFA", XCD: "$", XOF: "CFA", 
    XPF: "₣", YER: "﷼", ZAR: "R", ZMW: "ZK", ZWL: "$" 
  };  
  getCurrencySymbol(code: string): string { return this.currencyMap[code] || code; }

  constructor(private toastr: ToastrService) { }


  // **************** Method to start Loader ******************************
  startLoader() {
    this.loader = true;
  }
  // **************** Method to stop Loader ******************************
  stopLoader() {
    this.loader = false;
  }
  // ***************** Method to send Toast Msgs *************************
  showToastMesaages(type: any, msg: any) {
    this.toastr.clear();
    if (type == 'success') {
      this.toastr.success(msg);
    } else if (type == 'error') {
      this.toastr.error(msg);
    } else if (type == 'info') {
      this.toastr.info(msg);
    }
  }
}
