export const environment = {
	production: true,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	TenantId: 'E7749B6460D6855A8698',
	maxSurveyParticipants: 5,
	orgConnectionCode: '000095',
	envType: 'dev',
	BlueNumberIdentityServerClientId: "localClient",
	//BlueNumberSurveyAPI: "https://dev-bnsurvey.bluenumber.com",
	//BlueNumberGlobalAPI: "https://dev-bngen.bluenumber.com",
	//BlueNumberDataImportAPI: "https://dev-bndi.bluenumber.com",
	//BlueNumberIdentityServer: "https://dev-auth.bluenumber.com",
	//BlueNumberIssuerAPI: "https://dev-bnissuer.bluenumber.com",
	//BlueNumberDataReqAPI: "https://dev-bndm.bluenumber.com",

	//BluenumberSurveyOTPValidationUrl: "https://bluenumber.global/mm_process", // old
	BluenumberSurveyOTPValidationUrl: "https://millionmakers.org/mm_process",
	BlueNumberGlobalAPI: "https://dev-cog-bngen.bluenumber.com",
	BlueNumberDataReqAPI: "https://dev-cog-bndm.bluenumber.com",
	BlueNumberCredentialsAPI: "https://dev-cog-credential.bluenumber.com",

	//amplify settings
	BlueNumberCognitoAmplifyUrl: "https://dev-sso.bluenumber.com/signin/",

	// ******** Un Comment BlueNumberCognitoReturnUrl: "http://localhost:4200/ while running locally ********************
	// BlueNumberCognitoReturnUrl: "http://localhost:4200/auth/login/",

	// ************  Comment it while running locally ********************
	BlueNumberCognitoReturnUrl: "https://dev-survey.millionmakers.org/auth/login/",
	IpAPiUrl: "https://pro.ip-api.com/json?key=VyIZD0eqToHTt6J",
	BNFGlobalAPI: " https://dev-cog-bnf-global.bluenumber.com",

	cognito: {
		userPoolId: 'us-east-2_Zxy17fHDS',
		userPoolWebClientId: '7tmt86q7t9ilvua99ln51pc20k',
		// ************  Comment cookieStorage while running locally ********************
		// cookieStorage: {
		// 	domain: '.millionmakers.org',
		// 	secure: false,
		// 	path: '/',
		// 	expires: 365,
		// }
		// ************ Un Comment it while running locally ********************
		storage: localStorage,
		cookieStorage: false,
	}
};
